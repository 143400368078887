import $ from 'jquery';

export class PasswordToggle {

    static pluginKey: string = "plugin_PasswordToggle";
    static indicatorShowClass = "fa fa-eye";
    static indicatorHideClass = "fa fa-eye-slash";

    private element: JQuery;
    private input: JQuery;
    private toggle: JQuery;
    private visible: boolean = false;

    static RegisterPlugin() {
        $.fn.extend({
            passwordToggle: function(options?: any) {
                // ReSharper disable once SuspiciousThisUsage
                return this.each(function() {
                    const obj = $(this);
                    const elementAlreadyRegistered = obj.data(PasswordToggle.pluginKey);
                    if (!elementAlreadyRegistered) {
                        // ReSharper disable once WrongExpressionStatement
                        new PasswordToggle(obj, options);
                    }
                });
            }
        });
    }

    constructor(element: JQuery, options: any) {
        this.element = element;
        if (this.element.length) {
            this.element.data(PasswordToggle.pluginKey, this);
            this.input = this.element.find("input");
            this.toggle = this.element.find(".toggle");
            this.toggle.click(this.onToggle);
        }
    }

    private onToggle = (event: JQuery.Event<HTMLElement, null>) => {
        this.visible = !this.visible;
        let text = this.visible ? 'hide' : "show";
        let addClass = !this.visible ? PasswordToggle.indicatorShowClass : PasswordToggle.indicatorHideClass;
        let removeClass = this.visible ? PasswordToggle.indicatorShowClass : PasswordToggle.indicatorHideClass;
        let type = this.visible ? 'text' : 'password';

        if(this.visible) {
            this.toggle.addClass('visible');
        } else {
            this.toggle.removeClass('visible');
        }

        this.toggle.find('.text').html(text);
        this.toggle.find('.indicator').removeClass(removeClass).addClass(addClass);
        this.input.attr('type', type);
    }

}