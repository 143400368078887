import $ from 'jquery';

export interface IAjaxButtonOptions {
    classStatic: string,
    classActive: string,
}

export class AjaxButton {

    static pluginKey:string = "plugin_AjaxButton";

    static defaultOptions: IAjaxButtonOptions = {
        classStatic: "fa fa-chevron-right",
        classActive: "fa fa-refresh fa-spin"
    };

    private element: JQuery;
    private options: IAjaxButtonOptions;
    private indicator: JQuery;
    private active: boolean;

    static PluginAction(element: JQuery, method: string, args?: any) {
        let instance: AjaxButton = element.data(AjaxButton.pluginKey);
        switch (method) {
            case 'enable' :
                instance.setDisable(false);
                break;
            case 'disable' :
                instance.setDisable(true);
                break;
            case 'start' :
                instance.setActive();
                break;
            case 'stop' :
                instance.setInactive();
                break;
            default:
                throw new Error(`Unknown command ${method}`);
        }
        return instance;
    }

    static RegisterPlugin() {
        $.fn.ajaxButton = function (optionsOrMethod?: any, args?: any) {
            if (typeof optionsOrMethod == 'string') {
                return AjaxButton.PluginAction(this, optionsOrMethod, args);
            }
            return new AjaxButton(this, optionsOrMethod);
        };
    }

    constructor(element: JQuery, options: IAjaxButtonOptions) {
        this.element = element;
        this.options = $.extend(AjaxButton.defaultOptions, options);

        this.element.data(AjaxButton.pluginKey, this);
        this.indicator = this.element.find('.indicator');
        this.setInactive();
    }

    public setInactive() {
        this.active = false;
        this.indicator.removeClass(this.options.classActive).addClass(this.options.classStatic);
        this.setDisable(false);
    }

    public setActive() {
        this.active = true;
        this.indicator.removeClass(this.options.classStatic).addClass(this.options.classActive);
        this.setDisable(true);
    }

    public isActive(): boolean {
        return this.active;
    }

    setDisable(value: boolean) {
        this.element.prop('disabled', value);
    }
}