import {GTMEventService} from "@moula/core";
import URLHelper from "../../core/helpers/url";

export enum NavigationDirection {
    Back,
    Next
}

export function applicationNavigationEvent(url: string, direction: NavigationDirection = NavigationDirection.Next): void {
    const gtm = new GTMEventService();
    const label = direction === NavigationDirection.Next ? "Next Step Clicked" : "Back Clicked";
    
    gtm.emitGAEvent("Customer Application", label, url, true, () => {
        URLHelper.redirect(url);
    });
}