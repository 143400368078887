import $ from "jquery";
import parsley from "parsleyjs";

import { AjaxButton } from "./AjaxButton/ajax_button";
import { PasswordToggle } from "./PasswordToggle/password_toggle";
import { PasswordValidator } from "./PasswordValidator/password_validator";

// hacky to ensure parsely doesn't get treeshaken out
const p = parsley;

$(() => {
    //Register custom jquery components
    AjaxButton.RegisterPlugin();
    PasswordToggle.RegisterPlugin();
    PasswordValidator.RegisterPlugin();

    // Setup moula ui components
    $(".btn-ajax").ajaxButton();
    $(".password-toggle").passwordToggle();
    $(".password-validator").passwordValidator();

})