export function makeArray (d:any, fn:any): any[] {
    const ret: any[] = [];
    if (typeof fn !== "function")
        return ret;
    d = d || [];

    $.each(d, function () {
        ret.push(fn(this));
    });

    return ret;
}