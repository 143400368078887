import $ from 'jquery';

export class PasswordValidator {

    static PASSWORD_VALID: string = 'password_valid';
    static PASSWORD_INVALID: string = 'password_invalid';
    static pluginKey: string = "plugin_PasswordValidator";

    static indicatorValidClass: string = "valid";
    static indicatorInvalidClass: string = "invalid";

    static MIN_LENGTH: number = 8;

    private element: JQuery;
    private input: JQuery;
    private form: JQuery;

    private feedbackLowercase: JQuery;
    private feedbackUppercase: JQuery;
    private feedbackInteger: JQuery;
    private feedbackSpecial: JQuery;
    private feedbackMinLength: JQuery;

    private lowercase: boolean = false;
    private uppercase: boolean = false;
    private integer: boolean = false;
    private special: boolean = false;
    private minLength: boolean = false;

    private valid: boolean = false;

    static RegisterPlugin() {
        $.fn.passwordValidator = function (optionsOrMethod?: any) {
            if(typeof optionsOrMethod == "string") {
                return PasswordValidator.PluginAction(this, optionsOrMethod);
            }
            return new PasswordValidator(this, optionsOrMethod);
        };
    }

    static PluginAction(element: JQuery, method: string):boolean {
        let instance: PasswordValidator = element.data(PasswordValidator.pluginKey);
        switch (method) {
            case 'isValid' :
                return instance.valid;
            default:
                throw new Error(`Unknown command ${method}`);
        }
    }

    constructor(element: JQuery, options: any) {
        this.element = element;
        if (this.element.length) {
            let passwordId = this.element.data('pwValidatorFieldId');
            let formId = this.element.data('formId');

            this.element.data(PasswordValidator.pluginKey, this);
            this.input = $(`#${passwordId}`);
            this.form = $(`#${formId}`);

            this.feedbackLowercase = this.element.find('.pw-validator-lowercase');
            this.feedbackUppercase = this.element.find('.pw-validator-uppercase');
            this.feedbackInteger = this.element.find('.pw-validator-integer');
            this.feedbackSpecial = this.element.find('.pw-validator-special');
            this.feedbackMinLength = this.element.find('.pw-validator-minlength');
            this.input.keyup(this.validate);
        }
    }

    private validate = () => {
        let password: string = this.input.val() as string;
        let nonDigits = password.replace(/[^0-9]+/g, '') != password;

        this.lowercase = nonDigits && password.toUpperCase() != password;
        this.uppercase = nonDigits && password.toLowerCase() != password;
        this.integer = /\d/.test(password);
        this.special = password.replace(/[^0-9A-Za-z]+/g, '') != password;
        this.minLength = password.length >= PasswordValidator.MIN_LENGTH;
        this.valid = this.lowercase && this.uppercase && this.integer && this.special && this.minLength;
        this.form.trigger(this.valid ? PasswordValidator.PASSWORD_VALID : PasswordValidator.PASSWORD_INVALID);
        this.updateFeedback()
    };

    private updateFeedback() {
        if (this.lowercase) {
            this.feedbackLowercase.addClass(PasswordValidator.indicatorValidClass);
            this.feedbackLowercase.removeClass(PasswordValidator.indicatorInvalidClass);
        } else {
            this.feedbackLowercase.removeClass(PasswordValidator.indicatorValidClass);
            this.feedbackLowercase.addClass(PasswordValidator.indicatorInvalidClass);
        }

        if (this.uppercase) {
            this.feedbackUppercase.addClass(PasswordValidator.indicatorValidClass);
            this.feedbackUppercase.removeClass(PasswordValidator.indicatorInvalidClass);
        } else {
            this.feedbackUppercase.removeClass(PasswordValidator.indicatorValidClass);
            this.feedbackUppercase.addClass(PasswordValidator.indicatorInvalidClass);
        }

        if (this.integer) {
            this.feedbackInteger.addClass(PasswordValidator.indicatorValidClass);
            this.feedbackInteger.removeClass(PasswordValidator.indicatorInvalidClass);
        } else {
            this.feedbackInteger.removeClass(PasswordValidator.indicatorValidClass);
            this.feedbackInteger.addClass(PasswordValidator.indicatorInvalidClass);
        }

        if (this.special) {
            this.feedbackSpecial.addClass(PasswordValidator.indicatorValidClass);
            this.feedbackSpecial.removeClass(PasswordValidator.indicatorInvalidClass);
        } else {
            this.feedbackSpecial.removeClass(PasswordValidator.indicatorValidClass);
            this.feedbackSpecial.addClass(PasswordValidator.indicatorInvalidClass);
        }

        if (this.minLength) {
            this.feedbackMinLength.addClass(PasswordValidator.indicatorValidClass);
            this.feedbackMinLength.removeClass(PasswordValidator.indicatorInvalidClass);
        } else {
            this.feedbackMinLength.removeClass(PasswordValidator.indicatorValidClass);
            this.feedbackMinLength.addClass(PasswordValidator.indicatorInvalidClass);
        }
    }
}